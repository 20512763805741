import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Web3 from 'web3';
import ABI from './abi.json';
import NavBar from './Navbar';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CONTRACT_ADDRESS = "0xE64A4E6E33ACC8219884cD9D0fc19554591C6Cc8";

function App() {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [ticketQuantity, setTicketQuantity] = useState(1);
  const [ticketPrice, setTicketPrice] = useState();

  // Funktion zum Laden der Blockchain-Daten
  const loadBlockchainData = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      try {
        const connectedAccounts = await web3Instance.eth.getAccounts();
        const contractInstance = new web3Instance.eth.Contract(ABI, CONTRACT_ADDRESS);
        setWeb3(web3Instance);
        setAccounts(connectedAccounts);
        setContract(contractInstance);

        // Ticket-Preis vom Vertrag holen
        const price = await contractInstance.methods.ticketPrice().call();
        setTicketPrice(web3Instance.utils.fromWei(price, 'ether'));
      } catch (error) {
        console.error("Error loading blockchain data:", error);
        toast.error("Failed to load blockchain data. Check console for details.");
      }
    } else {
      toast.error('Please install MetaMask!');
    }
  };

  useEffect(() => {
    loadBlockchainData();

    // Ereignis-Listener für Kontowechsel und Wallet-Verbindung
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (newAccounts) => {
        setAccounts(newAccounts);
        loadBlockchainData(); // Blockchain-Daten neu laden, wenn Konten sich ändern
      });

      window.ethereum.on("connect", () => {
        loadBlockchainData(); // Neu laden bei Wallet-Verbindung
      });
    }

    // Aufräumen der Event-Listener
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", loadBlockchainData);
        window.ethereum.removeListener("connect", loadBlockchainData);
      }
    };
  }, []);

  const buyTickets = async () => {
    if (ticketQuantity === '') {
      toast('Please enter the number of tickets you want to purchase.');
      return;
    }
    const price = web3.utils.toWei((ticketPrice * ticketQuantity).toString(), 'ether');
    try {
      const gasPrice = await web3.eth.getGasPrice();
      const adjustedGasPrice = Number(gasPrice) + 1e9; // Hinzufügen von 1 Gwei für schnellere Transaktionen
      await contract.methods.buyTicket().send({
        from: accounts[0],
        value: price,
        gasPrice: adjustedGasPrice.toString(),
        gasLimit: 300000
      });
      toast(`Successfully bought ${ticketQuantity} tickets!`);
    } catch (error) {
      toast(`Please connect your wallet to buy tickets!`);
    }
  };

// Version 26.10.


  const [userTickets, setUserTickets] = useState([]);

const getUserTickets = async () => {
  if (contract && accounts.length > 0) {
    try {
      const tickets = await contract.methods.getUserTickets(accounts[0]).call();
      setUserTickets(tickets);
    } catch (error) {
      console.error("Error fetching tickets:", error);
      toast("Failed to fetch tickets!");
    }
  }
};



const handleTicketQuantityChange = (e) => {
  const input = e.target.value;
  // Allow the input to be empty
  if (input === '') {
    setTicketQuantity('');
  } else {
    const quantity = parseInt(input, 10);
    // Enforce the min and max bounds
    setTicketQuantity(Math.min(50, Math.max(1, isNaN(quantity) ? 1 : quantity)));
  }
};
const incrementTicketCount = () => {
  setTicketQuantity(prevQuantity => (prevQuantity < 50 ? prevQuantity + 1 : prevQuantity));
};

const decrementTicketCount = () => {
  setTicketQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
};


  return (
    <div className="App">
       <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NavBar  />
<Container> 
<Row>

<Col lg={8}>
  <div className="cardtop">
    <p style={{ fontSize: '60px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)'  }}> HERE TO SHARE.</p>

    <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' }}>
      World's first crypto lottery game with substantial prizes and a charitable purpose powered on the MATIC blockchain.
    </p>

    <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif' , textAlign: 'left'}}>
      Become a part of the community and decide on the purpose of the donation.
    </p>
   </div>
</Col>


<Col lg={4}>
<div className="cardimageoben">
    <img
      src="/pigg.webp"/>
  </div>    
</Col>  
</Row>
</Container>


     <Container>
 <Row>
 <Col lg={6}>
  <div className="card">
    <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '', fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>BUY YOUR TICKETS HERE!</p>
    <div className="buy-ticket-container">
      <div className="cardimageticket">
        <img src="/Ticket_Sharetoshi.webp" />
      </div>
      <div className="input-group mb-3">
      
        <input
          type="number"
          className="form-control"
          id="ticketQuantity"
          name="ticketQuantity"
          value={ticketQuantity}
          onChange={handleTicketQuantityChange}
          placeholder="1"
        />
       <Button className="increment-btn" onClick={incrementTicketCount}>+</Button>
<Button className="decrement-btn" onClick={decrementTicketCount}>-</Button>

      </div>

      <div className="cost-display">
        <p>You pay: ~{ticketPrice * (ticketQuantity || 0)} MATIC</p>
      </div>
      <Button variant="primary1" onClick={buyTickets}>Buy</Button>
    </div>
  </div>
</Col>





    <Col lg={6}>
    <div className="card">
      <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>
  PRIZE POT
</p>


<div className="cardimagejackpot">
    <img
      src="/Jackpot_Sharetoshi.webp"
    />
</div>    

<span style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif'}}>
  Winner gets:
</span>
<p style={{ fontSize: '40px', color: 'white', fontFamily: 'sans-serif', fontWeight: 'bold' }}>
  100.000 MATIC
</p>


    </div>
    </Col>
    </Row>
    </Container>



  <Container>
  <Row>
  <Col lg={6}>
      
      <div className="card">
      <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>
       DONATION AMOUNT </p>

<div className="cardimagehandmoney">
    <img
      src="/HandMoney_Sharetoshi.webp"/>
</div>    


<span style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif'}}>
  Community donates:
</span>
<p style={{ fontSize: '40px', color: 'white', fontFamily: 'sans-serif', fontWeight: 'bold' }}>
  10.000 MATIC
</p>


      </div>
  </Col>

  <Col lg={6}>
      <div className="card">
      <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)'  }}>GET YOUR TICKET COUNT</p>

      <div className="cardimagegraph">
      <img
      src="/Graph_Sharetoshi.webp"/>
      </div>   

      <Button variant="primary1" onClick={getUserTickets}>
        Ticket counter
      </Button>
        
      <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif',}}>
  You have {userTickets.length} ticket(s).
</p>
        
      </div>
  </Col>
  </Row>
  </Container>


  <Container>
  <Row>
  <Col lg={6}>
    <div className="card">
        <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>HOW TO PLAY</p>
        <br />
        
      <div className="video-container">
        <video width="100%" height="auto" controls>
            <source src="/Sharetoshi_Main.mp4" type="video/mp4"/>
          </video>
      </div>
    </div>
  </Col>



    <Col lg={6}>
      <div className="card1">
      <div className="winner_human">
        
        <img
          src="/winner.webp"
           />
             </div>  

         <div className="winner_human">
        
    <img
      src="/winner_human.webp"
       />
         </div>    
        
        <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>WINNER OF PREVIOUS ROUND</p>
      </div>
      

      <Col lg={12}>
      <div className="card1">
      <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>VOTING RESULT</p>

      <div className="voting">
        
        <img
          src="/VOTE.webp"
           />
             </div>  

         <div className="voting">
        
    <img
      src="/Community.webp"
       />
         </div>   

    
  
      </div>  
    </Col>

    </Col>
    </Row>
    </Container>




  <Container>
  <Row>
  <Col lg={12}>
      <div className="card1">
      <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}> PARTICIPATE, WIN AND DONATE </p>

<p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left'  }}>
sharetoshi Lottery, where tickets unlock the door to the pot. 
With each ticket purchased, you not only increase your chances of winning the pot 
but also contribute to charitable causes. The community decides where a portion of 
the winnings goes as a donation. Play with heart and win not just for yourself 
but for a better world.
</p>

      </div>
  </Col>
  </Row>
  </Container>


<Container>
<Row>
<Col lg={6}>
          <div className="cardimagemeta">
             <img
              src="/METAMASK.png"
              style={{ borderRadius: '50px', boxShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}
              alt="Metamask Logo"
             />
         </div>

</Col>


    <Col lg={6}>
    <div className="card">
  <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)' }}>HOW TO PARTICIPATE</p>

  <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' }}>
  <span style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
    <span style={{ color: 'green', marginRight: '8px', fontWeight: 'bold' }}>&#10003;</span>
    <span style={{ fontWeight: 'bold' }}> Connect your Wallet</span>
  </span>
 
  <span style={{ display: 'flex', marginLeft: '23px', fontSize: '15px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' }}>
   Connect your wallet with sharetoshi Lottery. 
   You find the "Connect Wallet" button in the navigation bar.
  </span>

  <br />
  <span style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
    <span style={{ color: 'green', marginRight: '8px', fontWeight: 'bold' }}>&#10003;</span>
    <span style={{ fontWeight: 'bold' }}> Buy Tickets </span>
  </span>

  <span style={{ display: 'flex', alignItems: 'center', marginLeft: '23px', fontSize: '16px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' }}>
   Choose the amount of tickets and press the buy button. 
   You can buy up to 50 tickets at once.
   </span>

  <br />
  <span style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
    <span style={{ color: 'green', marginRight: '8px', fontWeight: 'bold' }}>&#10003;</span>
    <span style={{ fontWeight: 'bold' }}> Transaction </span>
  </span>

  <span style={{ display: 'flex', alignItems: 'center', marginLeft: '23px', fontSize: '16px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' }}>
   The Metamask MetaMask pop-up will appear. Review the details 
   of the transaction and confirm it within the MetaMask pop-up 
   to proceed with the transaction."
   </span>

   <br />
  <span style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
    <span style={{ color: 'green', marginRight: '8px', fontWeight: 'bold' }}>&#10003;</span>
    <span style={{ fontWeight: 'bold' }}> Your Ticket </span>
  </span>

  <span style={{ display: 'flex', alignItems: 'center', marginLeft: '23px', fontSize: '16px', color: 'white', fontFamily: 'sans-serif', textAlign: 'left' }}>
   To easily check how many tickets you hold for the ongoing round, 
   just press the "Ticket counter" button.
   </span>
</p>

</div>

  </Col>
  </Row>
  </Container>



        <Row>
          <Col lg={12}>
            <div className="card">
              <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)'  }}>DONATION</p>


              <div className="cardimagehandmoney">
                <img
                  src="/HandMoney_Sharetoshi.webp"/>
              </div>   
             
              <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif' }}>      
              Thanks to the transparency that the blockchain offers, every donation is visible to everyone. 
              The collected donation amount and the handover of this to the organization designated by the 
              community will be documented in the form of a video on YouTube and all documents requested by 
              the community on our "X" account.
              </p>
            </div>
          </Col>
        </Row>




        <Row>
          <Col lg={12}>
            <div className="card">
              <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)'  }}>INFORMATION</p>
              <br />
              <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif' }}>
              Win big with sharetoshi Lottery, a fair on-chain game.
              Buy your Lottery Ticket to participate in the Game.
              Every Lottery Ticket is one chance to win the Prize Pot.
              10% worth of the pot amount will automatically be transfered to the next phase of the Lottery.
              10% worth of the pot will distributed into a donation account which will be donated and shown in a special YouTube donation video.
              10% worth of the pot will be used to deliver the donation with proof of delivery, 
              to manage the lottery, to cover developing fees and marketing fees. 
              Every donation will be documented on our "X"-Account. 
              The community decides via voting system on our "X"-Account where the donation lands.
              The Prize Pot will be distributed automatically via Smart Contract Logic.
              Holding more Lottery Tickets will increase the chance to win the Prize Pot.
              Connect your wallet to buy Lottery Tickets.
              </p>
            </div>
          </Col>
        </Row>


    
          <Row>
            <Col lg={12}>
              <div className="card">
                <p style={{ fontSize: '25px', color: 'white', fontFamily: 'sans-serif', textAlign: '' , fontWeight: 'bold', textShadow: '0 0 8px rgba(255, 255, 255, 0.7)'   }}>CONTRACT</p>
                <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif' }}>
                Here you can check the code for yourself. We want to move forward with full transparency and 
                are open to any suggestions for improvement. For contact inquiries please use contact@sharetoshi.xyz or Twitter/X
                </p>
                <p style={{ fontSize: '20px', color: 'white', fontFamily: 'sans-serif' }}>{CONTRACT_ADDRESS}</p>
              </div>
            </Col>
          </Row>
     
    </div>
  );
}

export default App;